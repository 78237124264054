import * as React from "react"
import Header from "../components/Header";
import {SEO} from "../components/seo";
import {Helmet} from "react-helmet";
import PrivacyPolicyContent from "../components/PrivacyPolicy";
import Footer from "../components/Footer";
import {INLINES, MARKS} from "@contentful/rich-text-types";
import {renderRichText} from "gatsby-source-contentful/rich-text";
import {graphql} from "gatsby";

const PrivacyPolicy = ({data}) => {
    const content = data.allContentfulPage.edges[0].node.sections[0].body;
    const options = {
        renderMark: {
            [MARKS.BOLD]: (text) => <b className="font-bold">{text}</b>,
        },
        renderNode: {
            [INLINES.HYPERLINK]: (node, children) => {
                const { uri } = node.data
                return (
                    <a href={uri} className="underline">
                        {children}
                    </a>
                )
            },
        },
    }

    return (
        <div>
            <Helmet>
                <title>Inter Protocol - Privacy Policy</title>
            </Helmet>
            <Header/>
            <div className={'section pt-20'}>
                <div className={'container container-slim'}>
                    {renderRichText(content, options)}
                    {/*<PrivacyPolicyContent/>*/}
                </div>
            </div>
            <div className={'section footer-section'}>
                <Footer/>
            </div>
        </div>
    )
}

export default PrivacyPolicy

export const Head = () => (
    <SEO />
)

export const query = graphql`
    {
        allContentfulPage(filter: {contentful_id: {eq: "68FDJ0CCJzWMrYiVRvDv8Y"}}) {
            edges {
                node {
                    id
                    sections {
                        ... on ContentfulSimpleTextPageSection {
                            body {
                                raw
                            }
                        }
                    }
                }
            }
        }
    }
`
